* {
  font-family: "Montserrat", sans-serif;
}

.App h2 {
  font-size: 48px;
  text-align: center;
}

.full_width {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.models_list, .materials_list {
  display: flex;
}

.model {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 320px;
  max-width: 400px;
  /* border: 2px solid chocolate; */
  transition: 0.5s;
}

.model img {
  width: 100%;
  max-height: 400px;
}

.model:hover {
  background-color: chocolate;
}

.material {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
}

.colors_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.color {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: 0.3s;
}

.color:hover {
  opacity: 0.7;
}

.info_container input {
  width: 500px;
  height: 40px;
}

.info_container textarea {
  width: 500px;
  height: 80px;
}